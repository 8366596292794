import { PiralPlugin } from 'piral';
import Keycloak, { KeycloakConfig, KeycloakProfile } from 'keycloak-js';

interface IAuthPluginAuthority {
  role: string;
  resource?: string;
}

interface IAuthPluginApi {
  cxAuth: {
    getToken(): string;
    getUserProfile(): KeycloakProfile;
    hasAllAuthority(...authorities: Array<IAuthPluginAuthority>): boolean;
    hasAnyAuthority(...authorities: Array<IAuthPluginAuthority>): boolean;
    isAuth(): boolean;
    logout(): void;
  };
}

interface IAuthPluginState {
  isAuth: boolean;
  token: string | null;
  userProfile: KeycloakProfile | null;
}

declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends IAuthPluginApi {}

  interface PiralCustomState {
    cxAuth: IAuthPluginState;
  }
}

enum Emit {
  TokenChange = 'cx-auth-token-change',
}

export function createAuthApi(
  config: KeycloakConfig,
): PiralPlugin<IAuthPluginApi> {
  const keycloakClient = new Keycloak(config);
  const { KEYCLOAK } = globalThis._CORTEX_CONFIG;

  keycloakClient.init({
    onLoad: 'login-required',
    checkLoginIframe: false,
    silentCheckSsoFallback: false,
  });

  return (context) => {
    const updateAuthState = (data: Partial<IAuthPluginState>) => {
      context.dispatch((state) => ({
        ...state,
        cxAuth: { ...state.cxAuth, ...data },
      }));
    };

    keycloakClient.onAuthSuccess = async () => {
      const token = keycloakClient.token;
      const userProfile = await keycloakClient.loadUserProfile();

      updateAuthState({ isAuth: true, token, userProfile });
      context.emit(Emit.TokenChange, token);
    };

    keycloakClient.onTokenExpired = async () => {
      await keycloakClient.updateToken(5);
      const token = keycloakClient.token;

      updateAuthState({ token });
      context.emit(Emit.TokenChange, token);
    };

    context.dispatch((state) => ({
      ...state,
      cxAuth: {
        isAuth: false,
        token: null,
        userProfile: null,
      },
    }));

    return {
      cxAuth: {
        getToken() {
          return context.readState((s) => s.cxAuth.token);
        },

        getUserProfile() {
          return context.readState((s) => s.cxAuth.userProfile);
        },

        hasAllAuthority(...authorities: Array<IAuthPluginAuthority>) {
          return authorities.every(({ role, resource }) =>
            keycloakClient.hasResourceRole(role, resource),
          );
        },

        hasAnyAuthority(...authorities: Array<IAuthPluginAuthority>) {
          return authorities.some(({ role, resource }) =>
            keycloakClient.hasResourceRole(role, resource),
          );
        },

        isAuth() {
          return context.readState((s) => s.cxAuth.isAuth);
        },

        logout() {
          keycloakClient.logout({
            redirectUri: KEYCLOAK.REDIRECT_URL,
          });
        },
      },
    };
  };
}
