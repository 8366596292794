import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library';

/**
 * Import UI core styles
 * @see https://code.roche.com/cortex/cortex-ui-core
 */
import '@cortex/ui-core/dist/cortex/fonts.css';
import '@cortex/ui-core/dist/cortex/theme.css';

/**
 * Register Shoelace icon library
 * @see https://shoelace.style/components/icon#icon-libraries
 */
registerIconLibrary('default', {
  resolver: (name) => `/icons/cortex/${name}.svg`,
});
