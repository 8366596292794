import React from 'react';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner/index';

import styles from './LoadingIndicator.module.css';

const LoadingIndicator: React.FC = () => (
  <div className={styles['loading-indicator']} data-e2e="loadingIndicator">
    <SlSpinner />
  </div>
);

export default LoadingIndicator;
