import React from 'react';
import { Link } from 'react-router-dom';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon/index';

import styles from './ExtShellBreadcrumbs.module.css';

interface IBreadcrumb {
  title: string;
  to?: string;
}

interface Props {
  separatorIconName?: string;
  items?: Array<IBreadcrumb>;
}

const ExtShellBreadcrumbs: React.FC<Props> = ({
  items = [],
  separatorIconName = 'chevron-compact-right',
}) => {
  return (
    <div
      className={styles['ext-shell-breadcrumbs']}
      data-e2e="extShellBreadcrumbs"
    >
      {items.map((item, $itemIndex) => (
        <div
          key={$itemIndex}
          className={styles['ext-shell-breadcrumbs__group']}
          data-e2e="extShellBreadcrumbsGroup"
        >
          {$itemIndex ? (
            <SlIcon
              name={separatorIconName}
              className={styles['ext-shell-breadcrumbs__icon']}
              data-e2e="extShellBreadcrumbsSeparatorIcon"
            />
          ) : null}

          <div
            className={styles['ext-shell-breadcrumbs__item']}
            title={item.title}
            data-e2e="extShellBreadcrumbsItem"
          >
            {item.to ? (
              <Link
                to={item.to}
                className={styles['ext-shell-breadcrumbs__link']}
                data-e2e="extShellBreadcrumbsItemLink"
              >
                {item.title}
              </Link>
            ) : (
              item.title
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExtShellBreadcrumbs;
